import * as React from 'react';

// components
import { Center, Container, Grid, MantineTheme, Space, Text, Title, createStyles, useMantineTheme } from '@mantine/core';

// illustrations
import rankingSVG from '../../images/illustrations/ranking.svg';

const useStyles = createStyles((theme: MantineTheme) => ({

    projectTitle: {
        lineHeight:1.3,
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(40px + 1vw)",
            textAlign: 'center',
            alignSelf:'center',
            marginLeft:theme.spacing.xl,
        }
    },

    textAlign: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(36px + 1vw)",
            textAlign: 'center',
            lineHeight:1.2,
            marginRight:theme.spacing.xl,
        }
    },

    imageAlign: {
        position: 'relative',
        top: '20px',
        left: '50px',
    }
}))

const ProjectenHeader = () => {
    const { classes } = useStyles();
    const theme: MantineTheme = useMantineTheme();

    return (
        <Container pt='5rem' size="lg">
            <Grid>
                <Grid.Col offset={2.5} offsetMd={0} offsetSm={2.2} sm={7} span={8}>
                    <Title order={1} my="xl">
                        <Text className={classes.projectTitle}
                            size={40}
                            color='#444444'
                            >
                            Kwalitatieve software
                            <br />
                            voor tevreden klanten
                        </Text>
                    </Title>
                    <Space h="xl" />
                    <Center
                        style={{ width: 615 }}>
                        <Text size={20} color='gray.7' mt="xl" className={classes.textAlign}>
                            Onze klantenportefeuille is breed én tevreden. Bekijk hier een aantal van onze realisaties, opgeleverd vanuit expertise, efficiëntie en een passie voor kwalitatieve eenvoud.
                        </Text>
                    </Center>
                </Grid.Col>
                <Grid.Col offset={3.5} offsetMd={0} span={4} className={classes.imageAlign}>
                    <img
                        src={rankingSVG}
                        alt={'Kwalitatieve software'}
                    />
                </Grid.Col>
            </Grid>
        </Container>
    );
};

export default ProjectenHeader;
