import * as React from 'react';
import type { ProjectType } from '../../types';
import {
    GatsbyImage,
    getImage
} from 'gatsby-plugin-image';
import { getAllProjects } from '../../services/projects/all-projects';
import { Link } from 'gatsby';
import {
    Badge,
    Button,
    Card,
    Container,
    Grid,
    MantineTheme,
    Stack,
    Text,
    Title,
    createStyles,
    useMantineTheme,
} from '@mantine/core';

const useStyles = createStyles((theme:MantineTheme) => ({
    projectTitle: {
        lineHeight: 1,
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(36px + 1vw)",
            paddingBottom: 10,
            paddingTop: 10,
            display: 'flex',
            lineHeight: 1.3,
        },
    },
    projectImage: {
        [theme.fn.smallerThan('md')]: {
            height:'auto'
        }
    },

    cardSize: {
        [theme.fn.smallerThan('md')]: {
            width: 400,
            marginBottom:20
        }
    },

    projectSummary:{
        [theme.fn.smallerThan('md')]:{
            fontSize: "calc(32px + 1vw)",
            color:theme.colors.dark[9],
            fontWeight:500,
            lineHeight:1.5,
        }
    },

    projectClient: {
        [theme.fn.smallerThan('md')]:{
            fontSize: "calc(32px + 1vw)",
        }
    }
}))

const ProjectenAll = () => {
    const projects: ProjectType[] = getAllProjects();
    const { classes } = useStyles();
    const theme: MantineTheme = useMantineTheme();

    interface ProjectProps {
        project: ProjectType;
        even: boolean;
    }

    const Project = ({
        project,
        even
    }: ProjectProps) => {
        return (
            <div
                style={{
                    backgroundColor: even ? theme.colors.gray[1] : theme.white,
                }}
            >
                <Container py="5rem" size="lg">
                    <Grid gutter="xl">

                        {/* Applied GridCol properties to make it responsive and center aligned */}

                        <Grid.Col offsetMd={0} offset={3} offsetSm={2} sm={3} span={7}>

                            {/* TODO maybe introduce a default picture if non is provided */}
                            {project.headerPicture &&
                                <Card p="lg" radius="sm" className={classes.cardSize}>
                                    <Card.Section>
                                        <GatsbyImage className={classes.projectImage} image={getImage(project.headerPicture.gatsbyImageData)!} alt="" />
                                    </Card.Section>
                                </Card>
                            }
                        </Grid.Col>

                        {/* Setting Alignment for smaller screens*/}
                        <Grid.Col offsetMd={0} offset={3} offsetSm={2} sm={9} span={5}>

                            <Stack align="flex-start">
                                <Badge color={project.tags[0] == 'Consultancy' ? 'brand.8' : 'pink.8'} radius="sm"
                                    sx={(theme) => ({
                                        backgroundColor: project.tags[0] == 'Consultancy' ? theme.colors.brand[2] : theme.colors.pink[2],
                                        [theme.fn.smallerThan('md')]:{
                                            fontSize:"calc(18px + 1vw)",
                                            padding:20
                                        }
                                    })}>
                                    {project.tags[0]}
                                </Badge>
                                <Title size="h2">
                                    <Text color="gray.8" className={classes.projectTitle}>
                                        {project.title}
                                    </Text>
                                </Title>
                                <Text color="gray.6" weight={500} mb={17} sx={{ lineHeight: 1 }} className={classes.projectClient}>
                                    In opdracht van {project.client.name}
                                </Text>
                                <Text color="gray.7" weight={500} mb={17} className={classes.projectSummary}>
                                    {project.summary}

                                </Text>
                                <Button
                                    size="xs"
                                    mt="sm"
                                    variant={project.tags[0] == 'Consultancy' ? 'filled' : 'light'}
                                    component={Link}
                                    to={`/projecten/${project.slug}`}
                                    sx={(theme) => ({
                                        fontSize: 18,
                                        padding: '10px 20px',
                                        backgroundColor: project.tags[0] == 'Consultancy' ? 'brand.8' : theme.colors.pink[5],
                                        '&:hover': {
                                            backgroundColor: project.tags[0] == 'Consultancy' ? 'brand.2' : theme.colors.pink[6],
                                        },
                                        [theme.fn.smallerThan('md')]:{
                                            fontSize:"calc(24px + 1vw)",
                                            padding:20
                                        }
                                    })}
                                >
                                    Meer lezen
                                </Button>
                            </Stack>
                        </Grid.Col>
                    </Grid>
                </Container>
            </div>
        );
    };

    return (
        <div>

            {
                projects.map((project: ProjectType, index) => (
                    <Project key={index} even={index % 2 == 0} project={project} />
                ))
            }
        </div>

    );


};

export default ProjectenAll;
