import * as React from 'react';

import { TbSend } from 'react-icons/tb';
import { Container, Text, Title, Stack, Button, Space, SimpleGrid, MantineTheme, createStyles} from '@mantine/core';

// illustrations
import projectIdeaSVG from '../../images/illustrations/project-idea.svg';

const useStyles = createStyles((theme:MantineTheme) => ({
    container: {
        [theme.fn.smallerThan('md')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxHeight:768,
        },
    },
    contentAlign: {
        [theme.fn.smallerThan('md')]: {
            alignItems:'center',
        }
    },
    textFont:{
        lineHeight: 1.6,
        [theme.fn.smallerThan('md')]:{
            fontSize: "calc(32px + 1vw)"
        }
    },
    buttonMd:{
        [theme.fn.smallerThan('md')]:{
            padding:20,
            fontSize: "calc(32px + 1vw)",
            minHeight: 80,
            height: 80,
        }
    },

    svgIcon: {
        [theme.fn.smallerThan('md')]:{
            width: 700,
            paddingTop: 20
        }
    }
}))

const ProjectenHier = () => {
    const { classes } = useStyles();
    return(
        <Container py='2rem' size="sm" className={classes.container}>
            <SimpleGrid cols={2}
                spacing="lg"
                breakpoints={[
                    {maxWidth:'md', cols:1, spacing:'md'},
                ]}
                >

                {/* Left grid starts here */}
                <div>
                    <Stack align='flex-start' className={classes.contentAlign}>
                        <Space h="xl" />
                        <Title order={3} size="h3">
                            <Text className={classes.textFont}>
                                Uw project hier?
                            </Text>
                        </Title>
                        <Text
                            color='gray.7'
                            weight={500}
                            mb={17}
                            className={classes.textFont}
                           >
                            Wij maken er werk van.
                        </Text>
                        <Button leftIcon={<TbSend size={20}/>} mt="sm"
                            component='a'
                            className={classes.buttonMd}
                            href='mailto:hello@qmino.com'>
                            Contacteer ons
                        </Button>
                    </Stack>
                </div>
                {/* Left grid ends here */}
                {/* Right grid starts here */}
                <div>
                    <img
                            src={projectIdeaSVG}
                            alt={'Qmino Hier'}
                            style={{top:30}}
                            className={classes.svgIcon}
                        />
                    </div>
                {/* Right grid ends here */}
            </SimpleGrid>
        </Container>
    );
};

export default ProjectenHier;
