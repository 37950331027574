import { graphql, useStaticQuery } from 'gatsby';
import type { ProjectType } from '../../types';

type GraphQLResult = {
	allContentfulProject: {
		nodes: ProjectType[];
	};
};

export const getAllProjects = (): ProjectType[] => {
    const data = useStaticQuery<GraphQLResult>(graphql`
        query allProjects {
            allContentfulProject {
                nodes {
                    id
                    title
                    tags
                    summary
                    headerPicture {
                        gatsbyImageData(
                            width: 400,
                        )
                    }
                    client {
                        name
                    }
                    slug
                }
            }
        }
    `);
    
    return data.allContentfulProject.nodes;
};
