import * as React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ProjectenHeader from '../../components/projecten-page/projecten-header';
import ProjectenHier from '../../components/projecten-page/projecten-hier';
import ProjectenAll from '../../components/projecten-page/projecten-all';

const ProjectenPage = () => {
    return (
        <Layout>
            <ProjectenHeader/>
            <ProjectenAll/>
            <ProjectenHier/>
        </Layout>
    );
};

export const Head = () => (
    <SEO title='Projecten'/>
);

export default ProjectenPage;
